import { SVGProps } from "react";
const Religiosos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={90} height={132} fill="none" {...props}>
    <path
      fill="#4BADF7"
      d="M82.485 38.358H52.116V7.242H37.618v31.116H7.25l.007 7.25-.007 7.248h30.368v71.881l7.25-.013 7.248.013v-71.88h30.369V38.357Z"
    />
    <path
      fill="#4BADF7"
      d="m59.365 132-14.505-.021-14.485.021V60.105H0L.014 45.6 0 31.116h30.375V0h28.99v31.116h30.368v28.99H59.365V132Zm-14.505-4.286 10.246.02V55.846h30.368V35.375H55.106V4.259H34.634v31.116H4.259L4.273 45.6l-.014 10.246h30.375v71.888l10.226-.02Z"
    />
  </svg>
);
export default Religiosos;
