export enum DigitsEnum {
  "TWO" = 2,
  "THREE" = 3,
  "FOUR" = 4,
  "FIVE" = 5,
  "SIX" = 6,
}

export enum AccountingTypeFilesEnum {
  ALL = "Todos",
  VOUCHER_COMMUNITY = "Voucher aporte 10% de Comunidades",
  VOUCHER_CONTRUCTION = "Voucher Alquiler de Obras",
  BALANCE = "Balance Mensual",
  BANK = "Estado de cuenta de Bancos",
  ACCOUNTS = "Acta de rendición de cuentas",
  OTHERS = "Otros Documentos",
  REMUNERATIONS = "Boletas de remuneraciones",
  ATTENDANCE = "Control de asistencia",
}

export enum TypeFileStatusEnum {
  PENDING = "POR REVISAR",
  OBSERVED = "OBSERVADO",
  ACCEPTED = "ACEPTADO",
}

export enum TypeAccountingReportsEnum {
  MAYOR = "mayor",
  AUX_ACCOUNTS = "aux-accounts",
  BALANCE = "balance",
  QUARTERLY = "quarterly",
  BUDGET_EXECUTION = "budget-execution",
  BUDGET = "budget",
  EEFF = "eeff",
  GENERAL_BALANCE = "general-balance",
  EEGGPP = "eeggpp",
  EEGGPPNN = "eeggppnn",
  CASH_FLOWS = "cash-flows",
  CHANGE_HERITAGE = "change-heritage",
  DAILY_BOOK = "daily-book",
  BOX_BOOK = "box-book",
  INVENTORY_BOOK = "inventory-book",
  MAYOR_BOOK = "mayor-book",
  CLOSING_SEAT = "closing-seat",
  SHOPPING_RECORD = "shopping-record",
  SALES_RECORD = "sales-record",
  SHOPPING_SALES_RECORD = "shopping-sales-record",
  ELECTRONIC_BOOK = "electronic-book",
  RECEIPTS_FEES = "receipts-fees",
  PATRIMONIO_REPORT = "patrimonio-report",
  TICKETS = "tickets",
}

export enum TypeReportEnum {
  DAY = "dia",
  MONTH = "mensual",
  YEAR = "anual",
  CUSTOM = "intervalos",
}

export enum ReportFiltersStateParamsType {
  COMPANY_ID = "companyId",
  COMPANY_URL = "companyUrl",
  HEADQUARTER_ID = "headquarterId",
  HEADQUARTER_CODE = "headquarterCode",
  YEAR = "year",
  ACCOUNT_NUMBER = "accountNumber",
  ACCOUNT_ID = "accountId",
  DIGITIS = "digits",
  MONTH = "month",
  TYPE = "type",
  DATE_START = "dateStart",
  DATE_END = "dateEnd",
  DATE_START_NUMBER = "dateStartNumber",
  DATE_END_NUMBER = "dateEndNumber",
  ALL_SEDES = "isAllSedes",
}

export enum EEGGPPParamsReportEnum {
  PRODUCTION_TITLE = "produccion",
  NET_SALES = "ventasNetas",
  DISCOUNTS = "desc_re_bo",
  PRODUCTION_TOTAL = "ttproduccion",

  VALUE_ADDED_TITLE = "valor_agregado",
  PURCHASES = "compras",
  EXPENSES_FOR_SERVICES = "gastos_servicio",
  VALUE_ADDED_TOTAL = "tt_valor_agregado",

  GROSS_OPERATING_TITLE = "excedente_bruto_explotacion",
  PERSONNEL_EXPENSES = "gastos_personal",
  TAX_EXPENSES = "gastos_tributos",
  GROSS_OPERATING_TOTAL = "tt_exc_bruto",

  RESULT_EXPLOITATION_TITLE = "resultado_explotacion",
  OTHER_EXPENSES = "ots_gastos_gest",
  ASSETS_PROVISIONS = "val_dete_act_pro",
  OTHERS_INCOMES = "ots_ingresos_gestion",
  RESULT_EXPLOITATION_TOTAL = "tt_explotacion",

  RESULTS_BEFORE_TITLE = "resultado_a_part_impuestos",
  FINANCIAL_INCOMES = "ingresos_financieros",
  DISCOUNTS_BONUSES = "desc_reb_bon",
  EARNINGS_PER_MONTH = "ganancias_med_activ",
  FINANCIAL_EXPENSES = "gastos_financieros",
  RESULTS_BEFORE_TOTAL = "total_resultado_part_impuestos",
}

export enum ProvisioningTabsEnum {
  ENTRIES = "entries",
  PAYMENTS = "payments",
  COMPLETE = "complete",
}

export enum BudgetStatusEnum {
  ENABLED = "enabled",
  DISABLED = "disabled",
  PENDING = "pending",
}
