import { SVGProps } from "react";
const Enseres = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={140} fill="none" {...props}>
    <path stroke="#057CC6" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={0.83} d="M46.169 79.574c-2.025 3.697-4.882 3.25-7.427 6.61" />
    <path fill="#3CA2EF" d="M48.233 77.36c-.129 1.862-1.27 2.79-2.293 2.768-.184-.778.117-2.332 2.293-2.768Z" />
    <path
      fill="#78BCFB"
      d="M46.755 76.571c-.308-.156-2.813 1.974-2.03 3.518.85 1.666 2.114 1.197 3.053.514.94-.682 1.27-2.069 1.27-2.069l-2.511.487.224-2.456-.006.006Z"
    />
    <path
      fill="#3CA2EF"
      d="M45.133 80.821a2.269 2.269 0 0 1-.588-.922 2 2 0 0 1-.078-.655c0-.061.118-.604.123-.598.75.52.895 1.504.917 2.047.487-.118 1.337-.213 2.137.234a2.102 2.102 0 0 1-.492.37c-.666.358-1.348.09-1.896-.358a1.25 1.25 0 0 1-.134-.118h.01Z"
    />
    <path
      stroke="#057CC6"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={0.83}
      d="M15.223 47.1c.995.99 2.158 1.795 3.238 2.69 1.079.895 2.108 1.907 2.718 3.176"
    />
    <path fill="#3CA2EF" d="M13.559 45.55c.19 1.276 1.029 1.857 1.733 1.785.084-.548-.212-1.605-1.733-1.784Z" />
    <path
      fill="#78BCFB"
      d="M14.535 44.93c.201-.129 2.047 1.208 1.594 2.315-.498 1.197-1.393.945-2.08.526-.683-.42-.99-1.359-.99-1.359l1.761.201-.29-1.683h.005Z"
    />
    <path
      fill="#3CA2EF"
      d="M15.882 47.77c.162-.184.302-.424.352-.67.034-.151.04-.302.017-.453 0-.04-.112-.414-.117-.408-.487.402-.537 1.085-.52 1.465-.341-.056-.934-.073-1.466.28.112.089.23.173.364.229.475.207.934-.011 1.286-.353a1.31 1.31 0 0 0 .084-.09Z"
    />
    <path
      stroke="#057CC6"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={0.83}
      d="M58.13 27.034c-1.365.33-2.775.414-4.167.621-1.387.207-2.79.543-3.97 1.309"
    />
    <path fill="#3CA2EF" d="M60.362 26.576c-.828.995-1.84 1.051-2.405.626.212-.509 1.012-1.264 2.405-.626Z" />
    <path
      fill="#78BCFB"
      d="M59.846 25.536c-.106-.213-2.377-.028-2.561 1.157-.196 1.281.705 1.527 1.504 1.527.806 0 1.55-.649 1.55-.649l-1.611-.743 1.118-1.292Z"
    />
    <path
      fill="#3CA2EF"
      d="M57.22 27.27c-.046-.241-.04-.52.044-.756.05-.145.128-.28.224-.397.028-.033.307-.29.313-.285.207.598-.107 1.208-.313 1.521.318.129.838.42 1.107.996a1.55 1.55 0 0 1-.425.01c-.515-.066-.794-.497-.917-.967-.011-.039-.017-.078-.028-.117l-.006-.006Z"
    />
    <path stroke="#266587" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={3.32} d="M34.67 12.79c1.224 22.18-2.204 64.441-2.299 85.793" />
    <path
      fill="#0086CF"
      d="M43.696 7.662c-1.241-.391-2.175-.084-2.51-.14.626-.715 1.682-2.069 1.873-3.042.296-1.499.184-2.41-1.275-3.009-2.405-.99-4.122.962-5.414 3.065C36.18.761 33.982-.503 32.02.174c-2.21.766-2.394 2.97-2.059 4.977-1.342-1.14-2.997-1.689-4.636-.755-1.51.862-1.817 2.886-1.073 4.362.564 1.119 1.532 1.835 2.645 2.265-.688.185-1.331.498-1.885 1.007-1.074 1-1.476 2.617-.721 3.931 1.062 1.857 3.176 1.555 5.1.638a4.206 4.206 0 0 0-.503 1.448c-.207 1.404.346 2.964 1.722 3.557 1.437.62 3.065-.1 4.01-1.253.699-.855 1.04-2.007 1.113-3.182.43 1.136 1.107 2.153 2.203 2.746 1.297.705 3.014.7 4.088-.402 1.102-1.125 1.09-2.948.447-4.296-.385-.805-1.012-1.47-1.744-2.024 2.242-.067 4.474-.783 4.602-2.953.073-1.202-.341-2.17-1.622-2.572l-.01-.006Z"
    />
    <path
      fill="#08AFF9"
      d="M33.117 9.748s-5.24-4.457-6.017-3.042c-.777 1.415 5.167 4.177 5.167 4.177s-7.645 1.276-6.157 3.188c1.488 1.913 7.007-1.84 7.007-1.84s-3.68 5.945-1.84 6.443c1.84.497 3.4-5.878 3.4-5.878s3.753 5.453 4.743 4.178c.99-1.276-3.4-5.24-3.4-5.24s7.36.564 6.934-1.063c-.425-1.627-6.867-.71-6.867-.71s5.805-4.53 4.602-5.878c-1.202-1.342-6.23 5.24-6.157 5.028.073-.213.285-7.785-1.7-7.22-1.985.565.285 7.857.285 7.857Z"
    />
    <path
      fill="#B3E8FF"
      d="M36.98 9.278a2.77 2.77 0 0 0-.626-.486c-.688-.397-1.504-.515-2.293-.442-.704.062-1.42.28-1.935.76-.766.71-.95 1.93-.548 2.892 1.22 2.93 6.515 2.002 6.102-1.331a2.454 2.454 0 0 0-.694-1.387l-.005-.006Z"
    />
    <path
      fill="#4885AF"
      d="M22.719 28.952s-3.982-10.284 4.166-17.923c4.96 9.652-4.166 17.923-4.166 17.923ZM23.316 31.983s-3.814-7.88-12.656-7.28c2.92 8.102 12.656 7.28 12.656 7.28Z"
    />
    <path
      stroke="#4885AF"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.32}
      d="M9.832 15.324c4.787 1.778 8.88 5.368 11.263 9.887 2.086 3.96 2.852 8.461 3.58 12.88a865.321 865.321 0 0 1 7.935 61.761"
    />
    <path
      fill="#1EA6E2"
      d="M8.97 6.958c-1.7.117-2.242 1.862-2.275 3.618-.437-.57-.962-1.057-1.594-1.348-1.063-.498-2.483-.492-3.356.375-.85.85-.85 2.192-.29 3.199.47.85 1.269 1.37 2.158 1.705-.917.062-1.817.336-2.477.89-.895.743-1.443 2.018-.95 3.142.469 1.074 1.694 1.493 2.79 1.32a3.206 3.206 0 0 0 1.13-.403c-.705 1.505-.929 3.16.53 3.982 1.035.582 2.293.258 3.07-.587.398-.436.638-.94.778-1.476.341.866.906 1.616 1.784 2.052 1.158.576 2.735.319 3.4-.867.722-1.286.28-2.578-.615-3.618 1.572.252 3.294.09 3.876-1.644.52-1.538-.487-3.25-3.44-3.372 1.639-1.024 3.154-2.377 2.366-4.25-.475-1.136-1.186-1.22-2.36-.98-.76.157-1.812.99-2.366 1.488-.05-.263.185-.995-.128-1.963-.325-1-1.085-1.314-2.025-1.252l-.005-.011Z"
    />
    <path
      fill="#5DC8ED"
      d="M9.43 16.498s3.518 4.066 2.416 4.686c-1.101.615-3.3-4.01-3.3-4.01s-.944 5.985-2.449 4.832c-1.504-1.152 1.393-5.491 1.393-5.491s-4.625 2.913-5.022 1.482C2.07 16.559 7.037 15.3 7.037 15.3s-4.284-2.897-3.294-3.68c.99-.783 4.116 2.623 4.116 2.623s-.492-5.749.783-5.43c1.275.319.598 5.363.598 5.363s3.507-4.563 4.564-3.635c1.057.934-4.055 4.905-3.887 4.849.168-.056 6.084-.274 5.654 1.28-.43 1.555-6.146-.173-6.146-.173h.005Z"
    />
    <path
      fill="#B3E8FF"
      d="M8.68 12.941c-2.61-.302-3.299 3.848-1 4.776.755.308 1.705.151 2.253-.447.375-.408.537-.968.582-1.521.05-.615-.05-1.258-.363-1.79a2.31 2.31 0 0 0-.386-.486c-.302-.286-.677-.487-1.09-.532h.005Z"
    />
    <path
      stroke="#266587"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.32}
      d="M13.418 35.328c6.241 9.065 8.607 20.171 10.614 30.992 2.008 10.822 3.099 23.992 8.579 33.532"
    />
    <path
      fill="#0086CF"
      d="M18.919 29.601c-1.007.134-1.566.66-1.829.727.207-.716.509-2.024.324-2.774-.285-1.163-.665-1.767-1.896-1.71-2.03.094-2.606 2.04-2.824 3.959-1.38-2.612-3.355-2.785-4.524-1.656-1.308 1.27-.716 2.892.185 4.206-1.326-.364-2.679-.207-3.535.995-.783 1.113-.335 2.645.677 3.445.772.61 1.689.794 2.617.733-.425.357-.783.794-1 1.336-.431 1.063-.18 2.343.788 3.026 1.364.962 2.768.05 3.825-1.23-.028.39 0 .788.123 1.19.319 1.063 1.225 1.986 2.393 1.952 1.225-.039 2.137-1.085 2.428-2.209.212-.839.072-1.767-.263-2.623.682.66 1.498 1.158 2.466 1.22 1.152.072 2.366-.504 2.768-1.633.408-1.164-.201-2.45-1.101-3.194-.537-.441-1.203-.71-1.902-.855 1.566-.789 2.908-2.03 2.287-3.613-.346-.872-.956-1.426-2.002-1.286l-.005-.006Z"
    />
    <path
      fill="#08AFF9"
      d="M12.117 34.573s-5.184-1.432-5.268-.168c-.084 1.258 5.038 1.253 5.038 1.253s-4.994 3.428-3.31 4.29c1.683.86 4.356-3.62 4.356-3.62s-.643 5.431.822 5.174c1.465-.257.47-5.285.47-5.285s4.457 2.623 4.737 1.393c.28-1.23-4.139-2.59-4.139-2.59s5.403-2.03 4.564-3.042c-.84-1.012-5.1 1.767-5.1 1.767s2.617-5.128 1.32-5.682c-1.298-.553-2.685 5.766-2.702 5.593-.022-.174-2.371-5.61-3.59-4.552-1.22 1.057 2.796 5.474 2.796 5.474l.006-.005Z"
    />
    <path
      fill="#B3E8FF"
      d="M14.7 32.968a2.254 2.254 0 0 0-.605-.14c-.615-.05-1.236.134-1.772.442-.481.274-.912.665-1.125 1.18-.307.755-.039 1.678.565 2.231 1.829 1.672 5.28-.732 3.881-2.958-.218-.352-.559-.604-.95-.755h.005Z"
    />
    <path fill="#266587" d="M44.262 49.438s3.3-6.169 13.405-5.744c-3.087 4.68-8.087 7.13-13.405 5.743Z" />
    <path
      stroke="#266587"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.32}
      d="M54.883 16.784C43.128 44.136 39.387 72.556 32.805 98.896"
    />
    <path fill="#266587" d="M48.838 33.801s1.381-7.449-5.212-11.487c-1.488 8.83 5.212 11.487 5.212 11.487Z" />
    <path
      fill="#1EA6E2"
      d="M60.684 11.051c-1.006.135-1.566.66-1.828.727.206-.715.508-2.024.324-2.773-.285-1.164-.666-1.768-1.896-1.712-2.03.095-2.606 2.042-2.824 3.96-1.381-2.612-3.355-2.785-4.524-1.656-1.309 1.27-.716 2.892.184 4.206-1.325-.364-2.678-.207-3.534.995-.783 1.113-.336 2.646.677 3.445.771.61 1.689.794 2.617.733-.425.358-.783.794-1.001 1.336-.43 1.063-.18 2.344.788 3.026 1.365.962 2.769.05 3.826-1.23-.028.391 0 .788.123 1.19.318 1.063 1.224 1.986 2.393 1.952 1.225-.039 2.136-1.084 2.427-2.209.213-.838.073-1.767-.263-2.622.683.66 1.5 1.157 2.467 1.219 1.152.072 2.365-.504 2.768-1.633.408-1.163-.202-2.45-1.102-3.193-.537-.442-1.202-.71-1.901-.856 1.566-.789 2.908-2.03 2.287-3.613-.347-.872-.956-1.426-2.002-1.286l-.006-.006Z"
    />
    <path
      fill="#5DC8ED"
      d="M53.874 16.023s-5.184-1.431-5.268-.168c-.083 1.259 5.04 1.253 5.04 1.253s-4.995 3.428-3.312 4.29c1.684.86 4.357-3.619 4.357-3.619s-.643 5.43.822 5.173c1.465-.257.47-5.285.47-5.285s4.457 2.623 4.737 1.393c.28-1.23-4.139-2.59-4.139-2.59s5.402-2.03 4.564-3.042c-.84-1.012-5.1 1.767-5.1 1.767s2.617-5.128 1.32-5.681c-1.298-.554-2.685 5.765-2.702 5.592-.022-.173-2.371-5.61-3.59-4.552-1.22 1.057 2.796 5.475 2.796 5.475l.005-.006Z"
    />
    <path
      fill="#B3E8FF"
      d="M56.465 14.418a2.256 2.256 0 0 0-.604-.14c-.615-.05-1.236.134-1.773.442-.48.274-.911.665-1.124 1.18-.307.755-.039 1.678.565 2.231 1.829 1.672 5.28-.732 3.881-2.958-.218-.352-.56-.604-.95-.755h.005Z"
    />
    <path
      fill="#0086CF"
      d="M47.051 30.166c-1.41.956-.995 2.74-.145 4.272-.66-.274-1.36-.425-2.058-.363-1.164.106-2.394.827-2.707 2.019-.308 1.163.375 2.32 1.364 2.908.834.497 1.79.542 2.724.38-.76.514-1.398 1.208-1.695 2.019-.397 1.096-.229 2.472.761 3.193.945.688 2.215.436 3.076-.263.324-.263.576-.576.771-.917.151 1.655.795 3.199 2.467 3.17 1.185-.016 2.108-.933 2.354-2.057.123-.576.073-1.13-.073-1.667.733.576 1.594.94 2.578.873 1.287-.09 2.523-1.102 2.5-2.466-.028-1.477-1.057-2.366-2.36-2.813 1.482-.576 2.886-1.583 2.517-3.373-.33-1.588-2.058-2.56-4.67-1.18.9-1.71 1.521-3.64-.106-4.865-.984-.738-1.638-.453-2.528.347-.576.52-1.062 1.773-1.297 2.477-.174-.207-.341-.956-1.102-1.633-.783-.699-1.6-.593-2.377-.061h.006Z"
    />
    <path
      fill="#08AFF9"
      d="M52.253 38.169s5.09 1.733 4.452 2.824c-.638 1.09-4.871-1.801-4.871-1.801s2.198 5.643.324 5.408c-1.879-.235-1.566-5.442-1.566-5.442s-2.522 4.849-3.59 3.809 2.59-4.63 2.59-4.63-5.162-.342-4.704-1.516c.459-1.175 4.877.19 4.877.19s-3.322-4.72-2.058-5.084c1.264-.363 3.221 4.329 3.221 4.329s.721-5.71 2.108-5.441c1.387.268-1.029 6.28-.917 6.146.112-.135 5.117-3.305 5.531-1.745.414 1.56-5.391 2.953-5.391 2.953h-.006Z"
    />
    <path
      fill="#B3E8FF"
      d="M49.816 35.478c-2.404 1.057-.911 4.983 1.544 4.63.805-.116 1.549-.726 1.722-1.526.118-.542-.022-1.107-.263-1.605-.268-.56-.676-1.062-1.213-1.364a2.128 2.128 0 0 0-.576-.224c-.403-.095-.833-.078-1.208.09h-.006Z"
    />
    <path
      fill="#1EA6E2"
      d="M35.211 25.367c-1.465.101-1.935 1.611-1.968 3.127-.375-.492-.828-.912-1.376-1.17-.917-.43-2.148-.424-2.903.325-.738.733-.732 1.89-.251 2.768.408.733 1.101 1.186 1.867 1.471-.794.056-1.57.291-2.141.766-.772.643-1.247 1.745-.822 2.718.408.929 1.465 1.286 2.41 1.141.358-.056.677-.179.979-.347-.61 1.303-.8 2.735.458 3.44.895.503 1.985.223 2.65-.51a2.98 2.98 0 0 0 .672-1.28c.296.75.783 1.398 1.544 1.773 1 .498 2.365.274 2.941-.75.626-1.112.24-2.225-.537-3.126 1.36.218 2.847.079 3.35-1.42.447-1.331-.42-2.807-2.97-2.92 1.415-.883 2.724-2.057 2.042-3.674-.414-.978-1.03-1.05-2.041-.844-.66.134-1.566.856-2.047 1.286-.045-.229.162-.86-.112-1.694-.28-.867-.934-1.141-1.75-1.085l.005.006Z"
    />
    <path
      fill="#5DC8ED"
      d="M35.608 33.61s3.042 3.518 2.091 4.05c-.95.53-2.852-3.468-2.852-3.468s-.816 5.173-2.12 4.178c-1.297-.996 1.203-4.748 1.203-4.748s-3.999 2.522-4.34 1.28c-.346-1.241 3.954-2.326 3.954-2.326s-3.702-2.505-2.846-3.182c.855-.677 3.556 2.265 3.556 2.265s-.425-4.972.677-4.692c1.102.28.514 4.636.514 4.636s3.032-3.948 3.943-3.143c.917.805-3.506 4.24-3.361 4.189.145-.05 5.262-.235 4.888 1.107-.375 1.342-5.313-.145-5.313-.145h.006Z"
    />
    <path
      fill="#B3E8FF"
      d="M34.958 30.54c-2.259-.262-2.852 3.322-.866 4.133.654.263 1.476.134 1.951-.386.325-.352.464-.839.504-1.314.039-.531-.045-1.085-.314-1.55a1.857 1.857 0 0 0-.33-.418 1.644 1.644 0 0 0-.94-.46l-.005-.005Z"
    />
    <path
      fill="#4885AF"
      d="M41.97 75.408c.52-3.786 2.199-8.086-5.329-13.26-1.006 6.04 1.068 11.08 5.33 13.26ZM42.293 74.664c1.381-3.21 1.996-7.326 11.09-8.114-2.047 5.195-6.303 8.26-11.09 8.114Z"
    />
    <path
      stroke="#4885AF"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.32}
      d="M46.551 56.304c-4.966 21.414-7.779 31.486-13.617 43.56"
    />
    <path
      fill="#1EA6E2"
      d="M43.971 48.268c-.29.973-.04 1.7-.084 1.963-.565-.48-1.638-1.292-2.399-1.431-1.174-.213-1.885-.112-2.338 1.029-.749 1.89.8 3.21 2.461 4.2-2.947.19-3.915 1.923-3.361 3.45.62 1.717 2.349 1.84 3.91 1.555-.873 1.062-1.287 2.36-.538 3.63.694 1.168 2.276 1.386 3.423.788.867-.459 1.415-1.22 1.739-2.092a3.38 3.38 0 0 0 .81 1.46c.795.827 2.064 1.124 3.082.52 1.438-.856 1.18-2.5.436-3.993.347.184.722.319 1.136.374 1.101.146 2.31-.301 2.757-1.386.464-1.13-.112-2.394-1.024-3.121-.676-.537-1.582-.788-2.5-.833.879-.353 1.667-.89 2.12-1.75.537-1.024.509-2.366-.363-3.194-.895-.85-2.316-.816-3.367-.302-.626.308-1.14.805-1.56 1.387-.079-1.75-.66-3.484-2.36-3.562-.94-.045-1.695.29-1.991 1.297l.011.011Z"
    />
    <path
      fill="#5DC8ED"
      d="M45.723 56.511s-3.428 4.144-2.31 4.737c1.113.593 3.205-4.088 3.205-4.088s1.084 5.962 2.56 4.776c1.477-1.186-1.52-5.458-1.52-5.458s4.692 2.807 5.055 1.364c.364-1.442-4.63-2.589-4.63-2.589s4.216-2.992 3.21-3.752c-1.007-.76-4.055 2.718-4.055 2.718s.358-5.76-.911-5.408c-1.27.352-.476 5.374-.476 5.374s-3.607-4.485-4.647-3.529c-1.04.956 4.166 4.81 3.999 4.754-.168-.05-6.09-.134-5.626 1.409.464 1.543 6.14-.313 6.14-.313l.006.005Z"
    />
    <path
      fill="#B3E8FF"
      d="M45.313 53.497a2.293 2.293 0 0 0-.375.492c-.302.543-.386 1.186-.319 1.801.056.548.235 1.107.615 1.504.565.588 1.516.722 2.265.398 2.276-.985 1.494-5.112-1.113-4.754a1.91 1.91 0 0 0-1.079.56h.006Z"
    />
    <path
      fill="#4885AF"
      d="M19.943 74.105s-3.031-7.102-10.85-6.94c2.232 7.259 10.85 6.94 10.85 6.94ZM21.509 76.996s-2.82-7.281 2.952-12.689c3.512 6.834-2.952 12.69-2.952 12.69Z"
    />
    <path
      stroke="#4885AF"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.32}
      d="M14.707 57.664c4.345 15.384 10.072 28.252 17.901 42.194"
    />
    <path
      fill="#1EA6E2"
      d="M12.677 50.656c-.296.828-.117 1.465-.167 1.695-.465-.442-1.348-1.197-2.002-1.353-1.007-.241-1.622-.19-2.07.771-.738 1.594.532 2.813 1.918 3.742-2.555.016-3.472 1.47-3.07 2.813.453 1.51 1.935 1.7 3.3 1.532-.805.872-1.225 1.974-.643 3.11.542 1.045 1.896 1.313 2.913.85.772-.353 1.281-.985 1.605-1.718.107.47.297.912.627 1.303.643.755 1.722 1.074 2.634.599 1.286-.666 1.14-2.103.576-3.423.29.18.61.313.962.38.945.18 2.007-.145 2.449-1.056.459-.951.022-2.075-.727-2.74-.56-.498-1.325-.761-2.114-.84.777-.257 1.482-.687 1.918-1.409.515-.855.56-2.013-.156-2.774-.727-.777-1.958-.822-2.886-.425-.554.235-1.023.638-1.415 1.119.017-1.516-.397-3.043-1.862-3.194-.811-.083-1.476.168-1.784 1.024l-.006-.006Z"
    />
    <path
      fill="#5DC8ED"
      d="M13.785 57.86s-3.16 3.405-2.226 3.976c.934.564 2.97-3.367 2.97-3.367s.643 5.195 1.974 4.25c1.331-.95-1.04-4.781-1.04-4.781s3.909 2.656 4.295 1.426c.386-1.23-3.87-2.46-3.87-2.46s3.786-2.378 2.958-3.082c-.833-.705-3.635 2.142-3.635 2.142s.593-4.955-.52-4.715c-1.113.24-.677 4.614-.677 4.614s-2.89-4.049-3.836-3.277c-.945.772 3.355 4.356 3.216 4.3-.14-.056-5.252-.414-4.922.94.325 1.353 5.313.033 5.313.033Z"
    />
    <path
      fill="#B3E8FF"
      d="M13.582 55.237a1.864 1.864 0 0 0-.347.408c-.285.453-.391 1-.364 1.538.023.475.146.967.46 1.33.458.538 1.274.7 1.934.454 2.013-.739 1.538-4.34-.727-4.155-.358.028-.688.19-.956.425Z"
    />
    <path stroke="#057CC6" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={0.83} d="M17.266 79.412c3.271 2.539 4.004.565 6.543 3.221" />
    <path fill="#3CA2EF" d="M14.18 77.164c.654 1.75 2.013 2.31 2.986 1.996-.05-.794-.783-2.203-2.986-1.996Z" />
    <path
      fill="#78BCFB"
      d="M15.375 75.99c.246-.241 3.26 1.084 2.953 2.79-.341 1.84-1.683 1.756-2.78 1.37-1.096-.386-1.806-1.622-1.806-1.622l2.55-.251-.917-2.293v.005Z"
    />
    <path
      fill="#3CA2EF"
      d="M18.138 79.597c.179-.314.296-.694.296-1.052 0-.218-.039-.442-.111-.649a7.182 7.182 0 0 0-.291-.536c-.57.715-.43 1.7-.297 2.225-.497.028-1.342.18-1.985.834.184.095.375.173.582.212.738.151 1.314-.302 1.71-.883.034-.045.062-.096.096-.151Z"
    />
    <path stroke="#057CC6" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={0.83} d="M20.64 11.297a75.367 75.367 0 0 0-.19 13.757" />
    <path fill="#3CA2EF" d="M21.135 8.58c.816 1.677.29 3.053-.604 3.54-.543-.582-1.063-2.081.604-3.54Z" />
    <path
      fill="#78BCFB"
      d="M19.454 8.635c-.346.017-1.448 3.115 0 4.066 1.566 1.018 2.433-.017 2.903-1.074.47-1.057.067-2.427.067-2.427l-1.935 1.678-1.035-2.237v-.006Z"
    />
    <path
      fill="#3CA2EF"
      d="M20.175 13.126a2.187 2.187 0 0 1-.968-.509 1.898 1.898 0 0 1-.391-.526 5.753 5.753 0 0 1-.19-.581c.911.078 1.526.855 1.817 1.314.363-.341 1.051-.856 1.969-.867a2.432 2.432 0 0 1-.24.57c-.398.638-1.125.75-1.818.638l-.174-.033-.005-.006Z"
    />
    <path stroke="#057CC6" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={0.83} d="M39.742 43.772a75.943 75.943 0 0 0-6.219 12.276" />
    <path fill="#3CA2EF" d="M41.377 41.546c0 1.868-1.079 2.87-2.097 2.914-.235-.76-.039-2.338 2.097-2.914Z" />
    <path
      fill="#78BCFB"
      d="M39.846 40.859c-.319-.14-2.673 2.158-1.79 3.651.962 1.605 2.193 1.052 3.082.308.89-.744 1.124-2.153 1.124-2.153l-2.472.66.056-2.466Z"
    />
    <path
      fill="#3CA2EF"
      d="M38.522 45.21a2.258 2.258 0 0 1-.649-.884 1.963 1.963 0 0 1-.123-.649c0-.061.073-.61.084-.61.783.47.995 1.444 1.057 1.98.475-.15 1.32-.307 2.147.09-.14.15-.29.296-.464.402-.637.403-1.336.18-1.912-.229-.045-.033-.096-.067-.14-.106v.005Z"
    />
    <path
      fill="#0086CF"
      d="M38.75 50.237c-1.275.347-1.907 1.124-2.232 1.258.146-.956.308-2.695-.067-3.64-.57-1.449-1.169-2.165-2.746-1.88-2.606.476-3.003 3.088-2.952 5.604-2.237-3.132-4.816-3.009-6.124-1.348-1.47 1.868-.42 3.86.967 5.397-1.772-.24-3.495.201-4.384 1.901-.822 1.572.028 3.473 1.476 4.329 1.102.649 2.321.727 3.507.486-.487.537-.867 1.158-1.063 1.902-.369 1.448.174 3.053 1.538 3.764 1.93 1.006 3.58-.414 4.72-2.254.034.514.14 1.018.364 1.515.598 1.314 1.923 2.35 3.428 2.103 1.571-.263 2.572-1.767 2.745-3.271.13-1.119-.212-2.293-.794-3.34.99.733 2.131 1.237 3.395 1.142 1.499-.112 2.964-1.057 3.283-2.59.33-1.571-.683-3.126-1.98-3.925-.772-.481-1.678-.705-2.606-.778 1.885-1.292 3.406-3.126 2.32-5.06-.598-1.069-1.481-1.673-2.807-1.315h.012Z"
    />
    <path
      fill="#08AFF9"
      d="M30.826 57.837s-6.94-.945-6.829.699c.112 1.639 6.723.738 6.723.738s-5.85 5.29-3.53 6.113c2.322.822 4.995-5.425 4.995-5.425s.111 7.12 1.963 6.532c1.85-.587-.314-6.901-.314-6.901s6.208 2.612 6.359.973c.15-1.639-5.788-2.623-5.788-2.623s6.621-3.556 5.363-4.72c-1.258-1.163-6.275 3.166-6.275 3.166s2.483-7.07.716-7.561c-1.767-.492-2.46 7.907-2.517 7.69-.055-.219-4.032-6.83-5.419-5.252-1.386 1.577 4.558 6.577 4.558 6.577l-.005-.006Z"
    />
    <path
      fill="#B3E8FF"
      d="M33.884 55.315a2.791 2.791 0 0 0-.805-.073c-.806.04-1.572.386-2.21.878-.57.442-1.062 1.018-1.24 1.717-.264 1.029.24 2.175 1.118 2.78 2.65 1.84 6.683-1.863 4.496-4.497-.341-.414-.828-.682-1.353-.81l-.006.005Z"
    />
    <path
      fill="#78BCFB"
      d="M57.4 112.989a34.037 34.037 0 0 1-.593-17.55H9.466c.582 2.518.884 5.079.884 7.64 0 3.344-.492 6.689-1.471 9.91a185.248 185.248 0 0 0-2.998 11.106C3.981 132.221 10.127 140 18.465 140h29.343c8.339 0 14.49-7.779 12.583-15.905a184.029 184.029 0 0 0-2.997-11.106h.005Z"
    />
    <path
      fill="#4BADF7"
      d="M31.132 137.165c-3.199-1.287-6.124-3.68-7.225-6.946-.8-2.377-.576-4.972-.135-7.444 1.102-6.101 3.473-11.598 4.212-17.817.067-.576.385-5.62.441-9.524H9.467c.582 2.517.884 5.078.884 7.639 0 3.345-.492 6.689-1.471 9.91a185.09 185.09 0 0 0-2.998 11.107c-1.901 8.125 4.245 15.904 12.583 15.904h29.343c-2.45 0-5.022-.872-7.488-.978-3.07-.135-6.336-.705-9.188-1.851Z"
    />
  </svg>
);
export default Enseres;
